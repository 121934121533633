import React, { useState } from 'react';
import LogoImage from '../assets/unbiax11.png';
import LinkedInLogo from '../assets/socia-linkedin@2x.png'; // Ensure this path is correct
import WS from '../assets/social-whatsapp@2x.png';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrivacyPolicyModal = ({ onClose }) => (
  <div 
      className="w-full bg-black text-white py-16 px-4"
  >
    <div
      className="bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full h-[80vh] relative overflow-y-auto"
    >
      <h2
        className="text-lg sm:text-xl md:text-2xl font-bold py-2 text-center"
        style={{ color: 'white' }}
      >
        Privacy Policy
      </h2>
      <div className="text-justify space-y-4" style={{ color: 'white' }}>
        <p>
          Access to certain services offered on the site may require the input of personal data,
          such as email address, first name, last name, full address, ID type and number, and other
          optional data or any other information that allows individualization. In all cases where
          you provide personal information, and in accordance with current legislation, you declare
          that the information provided is true.
        </p>
        <p>
          The data you enter on the site will be used for: sending product and service information,
          reporting irregularities, analysis and investigations, compliance, legal advice,
          applications for negotiation process services and claim management prior to judicial
          proceedings, internal and external communication systems, and obtaining statistics.
        </p>
        <p>
          Entering personal data on the site by users is voluntary; however, Unbiax declares that
          entering this data will facilitate the use of the services offered and the relationship
          with users. In cases where you provide your personal information, you accept and give
          your free, express, and informed consent for such personal information to be used for
          your own benefit, optimizing the quality of the service we offer to keep you informed of
          possible changes, and authorize it to be processed, stored, and collected in the
          company's database.
        </p>
        <p>
          Unbiax guarantees to users who enter their data on the site that such data will be
          encrypted to preserve the security, integrity, and confidentiality of data considered as
          personal. Unbiax makes all necessary efforts to avoid its alteration, loss, unauthorized
          consultation, or treatment, allowing detection of intentional or unintentional deviations
          of information, whether the risks come from human action or the technical means used. To
          this end, we notify our privacy practices and the alternatives regarding how your
          information is collected and used.
        </p>
        <p>
          The encryption system implies that the data can only be interpreted by Unbiax, and no
          intermediary will have access to the information.
        </p>
        <p>
          The user may access their personal data, rectify, cancel, or oppose its processing by
          notifying the database manager at hello@Unbiax.me (LATAM).
        </p>
        <p>
          Unbiax will not share this information with third parties. However, Unbiax may send
          promotional offers or special communications to its users, where the user will always
          have the option to request not to receive them.
        </p>
        <p>
          The user will be responsible, in any case, for the veracity of the data provided,
          reserving Unbiax the right to exclude any user who has provided false data, without
          prejudice to initiating legal actions.
        </p>
        <p>
          Unbiax reserves the right to provide information to regulatory bodies in any country
          and/or judicial authorities that require it, where there are founded reasons relating to
          public safety, national defense, or public health.
        </p>
      </div>
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-xl"
        style={{ color: 'white' }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  </div>
);

const Footer = () => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const handlePrivacyClick = () => setShowPrivacyModal(true);
  const handleCloseModal = () => setShowPrivacyModal(false);

  return (
    <footer className="footer bg-black w-full py-8 px-4 grid lg:grid-cols-2 gap-8">
      <div className="flex flex-col">
        <img src={LogoImage} alt="Unbiax logo" className="w-40 h-26" />
        <p className="mb-4 text-white text-sm">
        Unbiax’s mission is to empower organizations to address their most impactful challenges through data-centric AI. Founded by a dedicated team, Unbiax enables enterprises to harness the power of advanced AI technologies, including large language models (LLMs) and foundation models, by focusing on the most critical component—high-quality data.        </p>
        <button
          onClick={handlePrivacyClick}
          className="text-white text-xs underline hover:no-underline"
          aria-label="Read our privacy policy"
        >
          Privacy Policy
        </button>
        <p className="text-white text-xs mt-4">
          © Unbiax 2024 All Rights Reserved
        </p>
      </div>
      <div className="flex flex-col space-y-4">
        <a
          href="mailto:hello@unbiax.com"
          className="text-white text-lg font-bold"
        >
          hello@unbiax.com
        </a>
        <a
          href="tel:+56968484088"
          className="text-white text-lg font-bold flex items-center space-x-2"
        >
          <img src={WS} alt="Contact us on WhatsApp" className="w-6 h-6" />
          <span>+56 9 7765 7352</span>
        </a>
        <div className="text-center flex justify-start">
          <a
            href="https://www.linkedin.com/company/unbiax"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedInLogo}
              alt="Visit us on LinkedIn"
              className="w-6 h-6"
            />
          </a>
        </div>
      </div>

      {showPrivacyModal && <PrivacyPolicyModal onClose={handleCloseModal} />}
    </footer>
  );
};

export default Footer;



