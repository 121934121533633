import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import LogoImage from '../assets/unbiaxblacklogo.png';
import DemoModal from './Demo'; // Import the modal component
import Quiz from './Quiz'; // Import the quiz modal component
import '@fontsource/inter/400.css'; // Import the Inter font (weight 400)
import '@fontsource/inter/700.css'; // Import bold weight (700)
import image1 from '../assets/anim4.gif';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isModalOpenQuiz, setIsModalOpenQuiz] = useState(false); // State to manage modal visibility

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogin = () => {
    window.location.replace('https://unbiax.onrender.com');
  };

  const handleSmoothScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    setNav(false); // Close the mobile menu after clicking the link
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleOpenModalQuiz = () => {
    setIsModalOpenQuiz(true); // Open the quiz modal
  };

  const handleCloseModalQuiz = () => {
    setIsModalOpenQuiz(false); // Close the quiz modal
  };

  return (
    <>
      {/* Navbar */}
      <div
        className="fixed top-0 left-0 w-full flex justify-between items-center px-4 text-white z-30 bg-black"
        style={{
          height: window.innerWidth < 750 ? '60px' : '96px', // Adjust height for mobile
        }}
      >
        <img
          src={LogoImage}
          alt="Unbiax"
          className="transition-transform duration-300"
          style={{
            width: window.innerWidth < 750 ? '90px' : '150px', // Logo size adjustment for mobile
            height: window.innerWidth < 750 ? '15px' : '90px', // Logo height adjustment for mobile
          }}
        />

        <ul className="hidden md:flex items-center space-x-4">
          <li className="p-4">
            <a href="#inicio" onClick={(e) => handleSmoothScroll(e, 'inicio')}>Home</a>
          </li>
          <li className="p-4">
            <a href="#ventajas" onClick={(e) => handleSmoothScroll(e, 'ventajas')}>Products</a>
          </li>
          <li className="p-4">
            <a href="#FAQ" onClick={(e) => handleSmoothScroll(e, 'FAQ')}>Agents</a>
          </li>
          <li>
            <button className="nav-button nav-button-entrar" onClick={handleLogin}>Login</button>
          </li>
          <li>
            <button className="nav-button nav-button-demo" onClick={handleOpenModal}>Request a Demo</button>
          </li>
        </ul>

        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* Mobile Menu */}
        <ul
          style={{
            left: nav ? '0' : '-100%',
            width: '60%',
            textAlign: 'center',
            position: 'fixed',
            top: '0',
            height: '100%',
            transition: 'left 0.5s ease-in-out',
          }}
          className="z-20 bg-black text-white"
        >
          <li className="p-4">
            <a href="#inicio" onClick={(e) => handleSmoothScroll(e, 'inicio')}>Home</a>
          </li>
          <li className="p-4">
            <a href="#ventajas" onClick={(e) => handleSmoothScroll(e, 'ventajas')}>Products</a>
          </li>
          <li className="p-4">
            <a href="#FAQ" onClick={(e) => handleSmoothScroll(e, 'FAQ')}>FAQ</a>
          </li>
          <li>
            <button className="nav-button nav-button-entrar" onClick={handleLogin}>Login</button>
          </li>
          <li>
            <button className="nav-button nav-button-demo" onClick={handleOpenModal}>Request a Demo</button>
          </li>
        </ul>
      </div>

      {/* Home Section with Background Image */}
      <div
        id="inicio"
        className="relative min-h-screen bg-cover bg-center bg-no-repeat text-white flex items-center justify-center"
        style={{ backgroundImage: `url(${image1})` }}
      >
        <div className="bg-black bg-opacity-70 p-8 rounded-md max-w-[800px] text-center">
          <p className="font-bold text-3xl lg:text-4xl mb-4">
          AI agents designed for risk analysis, project management, and more.          </p>
          <p className="text-xl lg:text-xl leading-7">
          Tailored AI solutions for law firms, mining, large infrastructure projects, and professional service providers.
          </p>
        </div>
      </div>

      {/* Render the modal */}
      <DemoModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <Quiz isOpen={isModalOpenQuiz} onClose={handleCloseModalQuiz} />
    </>
  );
};

export default Navbar;
